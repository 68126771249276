<template>
    <div class="container">
        <div class="login-form">

            <div class="main">
                <a-form id="formlogin" autocomplete="off" :form="form" class="user-layout-login"    >
                    <a-form-item>
                        <h3 class="title">
                            中医药文化进校园
                        </h3>


                    </a-form-item>
                    <a-form-item>
                        <a-input  v-decorator="[
          'username',
          { rules: [{ required: true, message: '请输入学校' }] },
        ]"  size="large" type="text" style="margin-left: 2.6rem;margin-top: 3.24rem;"   class="login-input"  placeholder="学校"></a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-input-password v-decorator="[
          'password',
          { rules: [{ required: true, message: '请输入密码' }] },
        ]"  size="large" @keydown.enter="handleSubmit" style="margin-left: 2.60rem;margin-top: 1.23rem;"  class="login-input" type="text" placeholder="密码" ></a-input-password>
                    </a-form-item>
                    <a-form-item>
                        <a-button  size="large" style="margin-top: 2.72rem;margin-left: 2.36rem;font-size: 1.01rem; width: 16.8rem;height: 2.64rem; align-items: center; border-radius: 0.6rem;background: #CE2323;color:#fff;" @click="handleSubmit" class="login-button">登录</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>

    </div>

</template>

<script>
    import { setItem } from "@/utils/storage";


    var bConnect=0;
    export default {
        name: "login",
        data(){
            return {
                LoginModel:{
                    username:'',
                    password:'',
                    sign:'',
                    chipid:''
                },
                form: this.$form.createForm(this, { name: 'dynamic_rule' }),
                RN:'',
                ukeyAuthOpen:'',
            }
        },
        methods:{
            handleSubmit:async function(){

                
                let that=this;
                that.form.validateFields((err,values) => {
                that.LoginModel.username=values.username;
                that.LoginModel.password=values.password;
                    if (!err||err==null) {
                        that.$axios.post("api/Member/Login", that.LoginModel).then((res) => {
                            if (res.data.Message=="SUCCESS"){

                                setItem("Token", res.data.Data.Token);
                              setItem("UserID", that.LoginModel.username);
                                that.$router.push({name:'list'});
                            }else{
                                that.$message.error(res.data.Data);
                            }
                        });
                    }
                });           
            },

        },
        mounted(){

        },
        created(){

        }
    }
</script>

<style lang="less">
    /* 适配各种屏幕尺寸 */
    @media (min-width: 1024px){
        body,html{font-size: 18px!important;}
    }
    @media (min-width: 1100px) {
        body,html{font-size: 20px!important;}
    }
    @media (min-width: 1280px) {
        body,html{font-size: 22px!important;}
    }
    @media (min-width: 1366px) {
        body,html{font-size: 24px!important;}
    }
    @media (min-width: 1440px) {
        body,html{font-size: 25px!important;}
    }
    @media (min-width: 1680px) {
        body,html{font-size: 28px!important;}
    }
    @media (min-width: 1920px) {
        body,html{font-size: 33px!important;}
    }

    .container{

        width: 100%;
        height: 37.03rem;
        display: flex;

        background-image:url("../static/lgbg.jpg");
        background-size: 100%;
        background-repeat: no-repeat;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .login-header{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:auto;
        width: 20.8%;
        height: 13.8%;
        h1{
            color: white;
            font-size: 1.09rem;
            line-height: 1.09rem;
            text-align: center;
        }
    }
    .title{
        color: #CE2323;
        text-align: center;
        font-weight: bold;
        font-size: 2.97rem;

    }

    .login-form{

        display: flex;
        margin-left: 1.21rem;
        flex-direction: column;
    }

    .user-layout-login{
        font-size: 0.42rem;
    }
    .login-button{
        font-size: 0.6rem;
        padding: 0 0.33rem;
        height: 1.26rem;
        line-height: 0.39rem;
    }
</style>
<style lang="less" scoped>


    ::v-deep .ant-btn{
    border: 0;
}

    ::v-deep .ant-btn:hover, .ant-btn:focus{
        border: 0;
    }
    ::v-deep .ant-input-lg{
        background: transparent!important;
        font-size: 0.935rem!important;
        color: #121212!important;
        width:16.2rem!important;
        height: 2.53rem!important;
        border: 2px solid #AC7E7F!important;
        border-radius:0.6rem!important;
    }


    ::v-deep .ant-form-explain, .ant-form-extra{
        font-size:  0.935rem;
    }
    ::v-deep .ant-input-suffix{
        right: 8.36rem!important;
        font-size: 0.92rem;
    }
    ::v-deep .ant-form-explain{
        margin-left: 2.5rem;
    }
</style>